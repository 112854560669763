<template>
  <tr>
    <td
      v-for="refItem in referencedElement.items"
      :key="`item_value_${item.itemId}_${refItem.id}`"
    >
      {{ getReviewValue(item, refItem.id) }}
    </td>
    <td style="width: 40%">
      <v-textarea
        v-model="comment"
        :placeholder="$t('forms.reviewPlaceholder')"
        filled
        rows="2"
        auto-grow
        class="mt-4"
        :readonly="readOnly"
        @blur="update"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: () => {}
    },

    item: {
      type: Object,
      required: true
    },

    referencedElement: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    comment: ''
  }),

  mounted() {
    this.comment = this.value;
  },

  methods: {
    getReviewValue(reviewItem, itemId) {
      const vals = reviewItem.Values.filter((x) => x.ItemId === itemId);
      if (vals.length > 0) {
        return vals[0].Value;
      }

      return '';
    },

    update() {
      if (this.readOnly) {
        return;
      }

      // we don't need an item id because we always only have one column for the
      // review. However server side we're required to provide an item id so we're
      // simply passing an empty guid.
      const item = {
        index: this.item.ItemIndex,
        columnValues: { '00000000-0000-0000-0000-000000000000': this.comment }
      };

      this.$emit('change', item);
    }
  }
};
</script>
