<template>
  <div>
    <v-simple-table v-if="!loading">
      <thead class="secondary">
        <tr>
          <th
            v-for="item in referencedElement.items"
            :key="`item_${item.id}`"
            class="white--text"
            colspan="2"
          >
            {{ localize(item.title) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-show="items.length === 0">
          <td class="text-center muted" colspan="2">
            {{ $t('forms.noReviews') }}
          </td>
        </tr>

        <review-item
          v-model="tableValues[item.ItemIndex]"
          v-for="item in items"
          :key="`item_${value.referencedElementName}_index_${item.ItemIndex}`"
          :referencedElement="referencedElement"
          :item="item"
          :readOnly="readOnly || printView"
          @change="saveItem"
        ></review-item>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import reviewItem from './review-item.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    loading: true,
    items: [],
    reviews: {}
  }),

  computed: {
    ...mapState('feedbacks', {
      feedbackData: 'currentFeedbackData'
    }),

    referencedElement() {
      for (const page of this.feedbackData.formContent.pages) {
        for (const el of page.elements) {
          if (el.elementName === this.value.referencedElementName) {
            return el;
          }
        }
      }

      return null;
    },

    tableValues() {
      if (!this.value.tableItems) {
        return {};
      }

      // reviews are always stored as table item values but with an empty guid
      // so we can always access the review comment by an empty guid as the index
      const dict = Object.assign(
        {},
        ...this.value.tableItems.map((item) => ({
          [item.index]:
            item.columnValues['00000000-0000-0000-0000-000000000000']
        }))
      );

      return dict;
    }
  },

  async mounted() {
    this.loadReviewData();
  },

  methods: {
    ...mapActions({
      fetchReviewData: 'feedbacks/loadReviewData',
      saveTableItem: 'feedbacks/saveTableItem'
    }),

    async loadReviewData() {
      const feedbackId = this.$route.params.id;

      this.items = await this.fetchReviewData({
        feedbackId: feedbackId,
        elementName: this.value.referencedElementName
      });

      this.loading = false;
    },

    saveItem(reviewItem) {
      reviewItem.feedbackId = this.$route.params.id;
      reviewItem.elementId = this.value.id;

      this.saveTableItem(reviewItem);
    }
  },

  components: {
    reviewItem
  }
};
</script>

<style lang="scss" scoped>
.table-review-element
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
